import React from 'react';
import { graphql } from 'gatsby';
import { formatPage } from '../formatters/page';
import Body from '../components/Body';
import Layout from '../components/Layout';
import Meta from '../components/Meta';
import Section from '../components/Section';
import ContentWithSidebar from '../components/ContentWithSidebar';

const PageTemplate = ({ data }) => {
  const { title, body, metaDescription, metaTitle } = formatPage(data);

  return (
    <Layout>
      <Meta title={metaTitle || title} description={metaDescription} />
      <Section>
        <ContentWithSidebar>
          <h1>{title}</h1>
          <Body content={body} />
        </ContentWithSidebar>
      </Section>
    </Layout>
  );
};

export default PageTemplate;

export const pageFragment = graphql`
  fragment PageFragment on MarkdownRemark {
    id
    fields {
      path
      slug
    }
    frontmatter {
      title
      metaDescription
      metaTitle
    }
    html
  }
`;

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      ...PageFragment
    }
  }
`;
